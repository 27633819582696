import InboxIcon from '@mui/icons-material/MoveToInbox';
import { APP_ROUTES } from './Routes';

const MENU: any = [
  {
    path: APP_ROUTES.DASHBOARD,
    title: 'DashBoard',
    Icon: InboxIcon,
  },
  {
    path: APP_ROUTES.ASSIGNWORK,
    title: 'ROUTE_ASSIGN',
    Icon: InboxIcon,
  },
  {
    path: APP_ROUTES.VEHICLE,
    title: 'VECHICLE',
    Icon: InboxIcon,
  },
  {
    path: APP_ROUTES.STORE,
    title: 'STORES',
    Icon: InboxIcon,
  },
  {
    path: APP_ROUTES.LOCK,
    title: 'LOCK',
    Icon: InboxIcon,
  },

  { title: 'MY_PROFILE', path: APP_ROUTES.PROFILE, Icon: InboxIcon },
  // {
  //   title: 'CHANGE_PASSWORD',
  //   path: APP_ROUTES.CHANGEPASSWORD,
  //   Icon: InboxIcon,
  // },

  { title: 'LOG_OFF' },
];
export default MENU;
