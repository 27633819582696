import React from 'react';
import Header from '../../../common/Header';
import { useTranslation } from 'react-i18next';
import { APP_ROUTES } from '../../../common/constants/Routes';
import AddIcon from '@mui/icons-material/Add';
import { Grid } from '@mui/material';
import CircularIndeterminate from '../../../common/CircularLoading';
import Table from '../../../common/Table';
import { GridColDef } from '@mui/x-data-grid';
import { useNavigate } from 'react-router-dom';
import CustomButton from '../../../common/ui/CustomButton';
import { vechicelStatus } from '../../../common/constants/dropDownList';

function Lock() {
  const { t } = useTranslation();
  const loading = false;
  const navigate = useNavigate();

  const columns: GridColDef[] = [
    {
      headerName: t('LOCK_NO'),
      field: 'lock_no',
      sortable: false,
      disableColumnMenu: true,
      flex: 1,
    },
    {
      headerName: t('LOCK_NAME'),
      field: 'lock_name',
      sortable: false,
      disableColumnMenu: true,
      flex: 1,
    },

    {
      headerName: t('STATUS'),
      field: 'status',
      sortable: false,
      disableColumnMenu: true,
      flex: 1,
      renderCell: ({ row }: any) =>
        vechicelStatus.find(
          (data: any) => Number(data.Code) === Number(row?.status)
        )?.Category,
    },
    {
      headerName: t('ACTION'),
      field: 'Action',
      sortable: false,
      disableColumnMenu: true,
      width: 200,
      renderCell: ({ row }: any) => {
        const handleEditClick = () => {
          navigate(APP_ROUTES.LOCKEDit, {
            state: { data: { data: row, type: 'edit' } },
          });
        };

        const handleViewClick = () => {
          navigate(APP_ROUTES.LOCKVIEW, {
            state: { data: { data: row, type: 'view' } },
          });
        };
        return (
          <>
            {' '}
            <CustomButton
              text={t('VIEW')}
              className='mx-3'
              color={'info'}
              onClick={handleViewClick}
            />
            <CustomButton
              text={t('EDIT')}
              className='mx-3 '
              onClick={handleEditClick}
              color={'error'}
            />
          </>
        );
      },
    },
  ];
  const data: any[] = [
    {
      id: '1',
      lock_no: 'LOCK001',
      lock_name: 'Lock',
      status: '1',
    },
    {
      id: '2',

      lock_no: 'LOCK002',
      lock_name: 'Lock',
      status: '1',
    },
    {
      id: '3',
      lock_no: 'LOCK003',
      lock_name: 'Lock',
      status: '2',
    },
  ];
  return (
    <div>
      <Header
        header={t('LOCK')}
        buttonName={t('ADD_LOCK')}
        route={APP_ROUTES.LOCKADD}
        buttonIcon={AddIcon}
        view={true}
      />
      {loading ? (
        <Grid xs={12} my={5} display={'flex'} justifyContent={'center'}>
          <CircularIndeterminate />
        </Grid>
      ) : (
        <Grid xs={12} mb={5}>
          <Table
            // totalRecords={
            //   petitionTypeCount
            //     ? petitionTypeCount
            //     : petetionList?.totalRecords || 0
            // }
            rows={data}
            columns={columns}
            // handlePagination={handlePagination}
            // paginationModel={{
            //   page: Number(petetionList?.currentPage) - 1 || 0,
            //   pageSize: Number(petetionList?.pagesize) || 5,
            // }}
            rowHeight={60}
          />
        </Grid>
      )}
    </div>
  );
}
export default Lock;
