import React, { useState } from 'react';
import Header from '../../../common/Header';
import { useTranslation } from 'react-i18next';
import { APP_ROUTES } from '../../../common/constants/Routes';
import AddIcon from '@mui/icons-material/Add';
import { Grid } from '@mui/material';
import CircularIndeterminate from '../../../common/CircularLoading';
import Table from '../../../common/Table';
import { GridColDef } from '@mui/x-data-grid';
import { useNavigate } from 'react-router-dom';
import CustomButton from '../../../common/ui/CustomButton';
import UnlockModel from './UnlockModel';

function RouteAssign() {
  const { t } = useTranslation();
  const loading = false;
  const navigate = useNavigate();
  const [unlockModelOpen, setUnlockModelOpen] = useState(false);
  const [cancel, setCancel] = useState(false);
  const columns: GridColDef[] = [
    {
      headerName: t('VECHICLE'),
      field: 'vechicle',
      sortable: false,
      disableColumnMenu: true,
      flex: 1,
    },
    {
      headerName: t('LOCK'),
      field: 'lock',
      sortable: false,
      disableColumnMenu: true,
      flex: 1,
    },
    {
      headerName: t('DATE'),
      field: 'date',
      sortable: false,
      disableColumnMenu: true,
      flex: 1,
    },
    {
      headerName: t('NO_OF_STORES'),
      field: 'no_of_stores',
      sortable: false,
      disableColumnMenu: true,
      flex: 1,
    },
    {
      headerName: t('STATUS'),
      field: 'status',
      sortable: false,
      disableColumnMenu: true,
      flex: 1,
    },
    {
      headerName: t('ACTION'),
      field: 'Action',
      sortable: false,
      disableColumnMenu: true,
      width: 250,
      renderCell: ({ row }: any) => {
        const handleUnlockClick = () => {
          setUnlockModelOpen(true);
          setCancel(false);
        };
        const handleCancelClick = () => {
          setUnlockModelOpen(true);
          setCancel(true);
        };
        const handleViewClick = () => {
          navigate(APP_ROUTES.ASSIGNWORKVIEW, {
            state: { data: { data: row, type: 'view' } },
          });
        };

        const dateToCheck =
          row.status === 'completed' || row.status === 'Cancel';

        return (
          <>
            {' '}
            <CustomButton
              text={t('VIEW')}
              className='mx-3'
              color={'info'}
              onClick={handleViewClick}
            />
            <CustomButton
              text={t('CANCEL')}
              className='mx-3 '
              onClick={handleCancelClick}
              disabled={dateToCheck}
              color={'error'}
            />
            <CustomButton
              text={t('UNLOCK')}
              className='mx-3 '
              onClick={handleUnlockClick}
              disabled={dateToCheck}
              color={'secondary'}
            />
          </>
        );
      },
    },
  ];
  const data: any[] = [
    {
      id: '1',
      vechicle: 'TN28B0523 - KKN',
      lock: 'LOCK005',
      date: '27-08-2024',
      no_of_stores: 3,
      status: 'Not yet started',
      vechicle_id: '1',
      lock_id: '1',
      lock_status: '1',
      location: [
        {
          previous_location: null,
          route: 1,
          km: 0,
          location_name: 'HUB',
          time: { hours: 0, minutes: 46, seconds: '52' },
          status: 'moving',
        },
        {
          previous_location: 1,
          route: 2,
          km: 12,
          location_name: 'Salem',
          time: { hours: 0, minutes: 46, seconds: '52' },
          status: 'pending',
        },

        {
          previous_location: 3,
          route: 4,
          km: 5,
          location_name: 'Mallur',
          time: { hours: 0, minutes: 46, seconds: '52' },
          status: 'pending',
        },
        {
          previous_location: 2,
          route: 3,
          km: 3,
          location_name: 'Gate',
          time: { hours: 0, minutes: 46, seconds: '52' },
          status: 'pending',
        },
        {
          previous_location: 4,
          route: 1,
          km: 2,
          location_name: 'HUB',
          time: { hours: 0, minutes: 46, seconds: '52' },
          status: 'pending',
        },
      ],
    },
    {
      id: '2',
      vechicle: 'TN28V0523 - KKN',
      lock: 'LOCK006',
      date: '27-08-2024',
      no_of_stores: 3,
      status: 'Moving',
      vechicle_id: '1',
      lock_id: '1',
      lock_status: '2',
      location: [
        {
          previous_location: null,
          route: 1,
          km: 0,
          location_name: 'HUB',
          time: { hours: 0, minutes: 46, seconds: '52' },
          status: 'completed',
        },
        {
          previous_location: 1,
          route: 2,
          km: 12,
          location_name: 'Salem',
          time: { hours: 0, minutes: 46, seconds: '52' },
          status: 'completed',
        },
        {
          previous_location: 3,
          route: 4,
          km: 5,
          location_name: 'Mallur',
          time: { hours: 0, minutes: 46, seconds: '52' },
          status: 'moving',
        },
        {
          previous_location: 2,
          route: 3,
          km: 3,
          location_name: 'Gate',
          time: { hours: 0, minutes: 46, seconds: '52' },
          status: 'pending',
        },

        {
          previous_location: 4,
          route: 1,
          km: 2,
          location_name: 'HUB',
          time: { hours: 0, minutes: 46, seconds: '52' },
          status: 'pending',
        },
      ],
    },
    {
      id: '3',
      vechicle: 'TN28E0523 - KKN',
      lock: 'LOCK015',
      date: '27-08-2024',
      no_of_stores: 3,
      status: 'completed',
      vechicle_id: '1',
      lock_id: '1',
      lock_status: '1',
      location: [
        {
          previous_location: null,
          route: 1,
          km: 0,
          location_name: 'HUB',
          time: { hours: 0, minutes: 46, seconds: '52' },
          status: 'completed',
        },
        {
          previous_location: 1,
          route: 2,
          km: 12,
          location_name: 'Salem',
          time: { hours: 0, minutes: 46, seconds: '52' },
          status: 'completed',
        },
        {
          previous_location: 3,
          route: 4,
          km: 5,
          location_name: 'Mallur',
          time: { hours: 0, minutes: 46, seconds: '52' },
          status: 'completed',
        },
        {
          previous_location: 2,
          route: 3,
          km: 3,
          location_name: 'Gate',
          time: { hours: 0, minutes: 46, seconds: '52' },
          status: 'completed',
        },

        {
          previous_location: 4,
          route: 1,
          km: 2,
          location_name: 'HUB',
          time: { hours: 0, minutes: 46, seconds: '52' },
          status: 'completed',
        },
      ],
    },
    {
      id: '4',
      vechicle: 'TN28V0523 - KKN',
      lock: 'LOCK006',
      date: '27-08-2024',
      no_of_stores: 3,
      status: 'Cancel',
      lock_status: '1',
      vechicle_id: '1',
      lock_id: '1',
      location: [
        {
          previous_location: null,
          route: 1,
          km: 0,
          location_name: 'HUB',
          time: { hours: 0, minutes: 46, seconds: '52' },
          status: 'Cancel',
        },
        {
          previous_location: 1,
          route: 2,
          km: 12,
          location_name: 'Salem',
          time: { hours: 0, minutes: 46, seconds: '52' },
          status: 'Cancel',
        },
        {
          previous_location: 3,
          route: 4,
          km: 5,
          location_name: 'Mallur',
          time: { hours: 0, minutes: 46, seconds: '52' },
          status: 'Cancel',
        },
        {
          previous_location: 2,
          route: 3,
          km: 3,
          location_name: 'Gate',
          time: { hours: 0, minutes: 46, seconds: '52' },
          status: 'Cancel',
        },

        {
          previous_location: 4,
          route: 1,
          km: 2,
          location_name: 'HUB',
          time: { hours: 0, minutes: 46, seconds: '52' },
          status: 'Cancel',
        },
      ],
    },
  ];
  return (
    <div>
      <Header
        header={t('ROUTE_ASSIGN')}
        buttonName={t('ADD_Route')}
        route={APP_ROUTES.ASSIGNWORKADD}
        buttonIcon={AddIcon}
        view={true}
      />
      {loading ? (
        <Grid xs={12} my={5} display={'flex'} justifyContent={'center'}>
          <CircularIndeterminate />
        </Grid>
      ) : (
        <Grid xs={12} mb={5}>
          <Table
            // totalRecords={
            //   petitionTypeCount
            //     ? petitionTypeCount
            //     : petetionList?.totalRecords || 0
            // }
            rows={data}
            columns={columns}
            // handlePagination={handlePagination}
            // paginationModel={{
            //   page: Number(petetionList?.currentPage) - 1 || 0,
            //   pageSize: Number(petetionList?.pagesize) || 5,
            // }}
            rowHeight={60}
          />
        </Grid>
      )}
      <UnlockModel
        open={unlockModelOpen}
        setOpen={setUnlockModelOpen}
        cancel={cancel}
      />
    </div>
  );
}

export default RouteAssign;
