import React from 'react';
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';
import { APP_ROUTES, PUBLIC_ROUTES } from '../common/constants/Routes';
// import PageNotFound from '../common/ui/PageNotFound';

import AppLayout from './AppLayout';
import DashboardScreen from '../features/App/DashboardScreen/DashboardScreen';
import ProfileScreen from '../features/App/ProfileScreen/ProfileScreen';
import ChangePassword from '../features/App/ChangePassword/ChangePassword';
import RouteAssign from '../features/App/Route Assign/RouteAssign';
import AddRoute from '../features/App/Route Assign/AddRoute';
import Vehicel from '../features/App/Vehicel/Vehicel';
import VechicelAdd from '../features/App/Vehicel/VechicelAdd';
import VehicelEdit from '../features/App/Vehicel/VehicelEdit';
import VehicleView from '../features/App/Vehicel/VehicleView';
import ViewRoute from '../features/App/Route Assign/ViewRoute';
import Stores from '../features/App/Stores/Stores';
import AddStores from '../features/App/Stores/AddStores';
import ViewStores from '../features/App/Stores/ViewStores';
import EditStore from '../features/App/Stores/EditStore';
import Lock from '../features/App/Lock/Lock';
import LockAdd from '../features/App/Lock/LockAdd';
import LockEdit from '../features/App/Lock/LockEdit';
import LockView from '../features/App/Lock/LockView';

const AppRoutes = ({ isLogged }: any) => {
  const location = useLocation();

  return (
    <Routes>
      {isLogged === true ? (
        <Route path='/' element={<AppLayout />}>
          <Route path={APP_ROUTES.DASHBOARD} element={<DashboardScreen />} />
          <Route path={APP_ROUTES.PROFILE} element={<ProfileScreen />} />
          <Route
            path={APP_ROUTES.CHANGEPASSWORD}
            element={<ChangePassword />}
          />

          {/* TO ADD ROUTE HERE */}
          <Route path={APP_ROUTES.ASSIGNWORK} element={<RouteAssign />} />
          <Route path={APP_ROUTES.ASSIGNWORKADD} element={<AddRoute />} />
          <Route path={APP_ROUTES.VEHICLE} element={<Vehicel />} />
          <Route path={APP_ROUTES.VEHICLEADD} element={<VechicelAdd />} />
          <Route path={APP_ROUTES.VEHICLEEDIT} element={<VehicelEdit />} />
          <Route path={APP_ROUTES.VEHICLEVIEW} element={<VehicleView />} />
          <Route path={APP_ROUTES.ASSIGNWORKVIEW} element={<ViewRoute />} />
          <Route path={APP_ROUTES.STORE} element={<Stores />} />
          <Route path={APP_ROUTES.STOREADD} element={<AddStores />} />
          <Route path={APP_ROUTES.STOREVIEW} element={<ViewStores />} />
          <Route path={APP_ROUTES.STOREEDit} element={<EditStore />} />
          <Route path={APP_ROUTES.LOCK} element={<Lock />} />
          <Route path={APP_ROUTES.LOCKADD} element={<LockAdd />} />
          <Route path={APP_ROUTES.LOCKEDit} element={<LockEdit />} />
          <Route path={APP_ROUTES.LOCKVIEW} element={<LockView />} />
          {!Object.values(PUBLIC_ROUTES).includes(location.pathname) ? (
            <Route path='*' element={<Navigate to={APP_ROUTES.DASHBOARD} />} />
          ) : (
            <></>
          )}
        </Route>
      ) : (
        <></>
      )}
    </Routes>
  );
};

export default AppRoutes;
