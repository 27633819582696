/* eslint-disable jsx-a11y/alt-text */
import React from 'react';
import { Box, Grid, Paper, Typography, useTheme } from '@mui/material';
import { Link } from 'react-router-dom';

function DashboardCard({
  total,
  content,
  icon,
  classname,
  navaigate,
}: {
  total: any;
  content: any;
  icon?: any;
  classname?: any;
  navaigate: any;
}) {
  const theme = useTheme();
  return (
    <Paper elevation={3}>
      <Link to={navaigate}>
        <Box
          className={`dashboard-card ${classname}`}
          px={2}
          justifyContent={'space-evenly'}
        >
          <Grid mb={3} mt={3} mr={1}>
            <img src={icon} className='volunteer-image' />
          </Grid>

          <Grid>
            <Typography
              color={theme.palette.common.black}
              fontSize={35}
              fontWeight={600}
              textAlign={'center'}
            >
              {total}
            </Typography>
            <Typography
              variant='caption'
              color={theme.palette.grey[500]}
              fontSize={14}
              fontWeight={600}
              ml={1}
            >
              {content}
            </Typography>
          </Grid>
        </Box>
      </Link>
    </Paper>
  );
}

export default DashboardCard;
