import React from 'react';
import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineOppositeContent from '@mui/lab/TimelineOppositeContent';
import TimelineDot from '@mui/lab/TimelineDot';
import { Chip, Typography } from '@mui/material';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';

function TimeLine({ location }: { location: any }) {
  console.log(location);
  const color: any = {
    completed: 'success',
    pending: 'error',
    moving: 'secondary',
    Cancel: 'grey',
  };

  return (
    <div>
      <Timeline>
        {location.map((val: any, index: number) => (
          <TimelineItem key={index}>
            <TimelineOppositeContent
              color={
                color[val.status] === 'success'
                  ? 'primary'
                  : color[val.status] || 'secondary'
              }
            >
              {val.km} KM
            </TimelineOppositeContent>
            <TimelineSeparator>
              <TimelineDot color={color[val.status] || 'secondary'} />
              {location.length !== index + 1 && <TimelineConnector />}
            </TimelineSeparator>
            <TimelineContent>
              <Chip
                icon={<LocalShippingIcon />}
                label={val.location_name}
                color={
                  color[val.status] === 'grey'
                    ? 'default'
                    : color[val.status] || 'secondary'
                }
              />
              <Typography fontSize={12}>
                Reach At : {val?.time?.hours}hr {val?.time?.minutes}min{' '}
                {val?.time?.seconds}sec
              </Typography>
            </TimelineContent>
          </TimelineItem>
        ))}
      </Timeline>
    </div>
  );
}

export default TimeLine;
