import { Grid, Typography, useTheme } from '@mui/material';
import { useForm } from 'react-hook-form';
import React from 'react';
import './Login.scss';
import FormInput from '../../../common/ui/Forms/FormInput/FormInput';
import LabelWithIcon from '../../../common/ui/LabelWithIcon';
import CustomButton from '../../../common/ui/CustomButton';
import icon from '../../../common/icons/YAA PETROLEUM.png';
import { APP_ROUTES, AUTH_ROUTES } from '../../../common/constants/Routes';
import { Link, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
  fetchLoginRequest,
  fetchOtpRequest,
} from '../../../store/auth/_auth.slice';
import { useDispatch } from 'react-redux';

const LoginForm = () => {
  const theme = useTheme();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    watch,
    trigger,
    clearErrors,
  } = useForm({
    defaultValues: {
      email: 'keerthivasan.v@yaacreations.com',
      password: 'Test@12345',
    },
  });
  const username = watch('email');

  const onSubmit = (data: any) => {
    console.log(data);
    if (data) {
      const handleSuccess = () => {
        navigate(APP_ROUTES.DASHBOARD);
        localStorage.setItem('user', data?.email);
        window.location.reload();
      };
      const payload: any = {
        data,
        navigate,
        handleSuccess,
      };
      dispatch(fetchLoginRequest(payload));
      handleSuccess(); // to remove after api
    }
    //
  };

  const handePassword = async () => {
    const isEmailValid = await trigger('email');
    clearErrors('password');
    if (isEmailValid && username) {
      const handleSuccess = () => {
        navigate(AUTH_ROUTES.LOGIN_OTP_VERIFY, { state: { email: username } });
      };
      const payload: any = {
        email: username,
        subject_type: '1',
        navigate,
        handleSuccess,
      };
      dispatch(fetchOtpRequest(payload));
    }
  };

  return (
    <Grid>
      <Grid justifyContent='center' display={'flex'} mb={2}>
        {/* <SvgConverter
              Icon={icon}
              width={400}
              className='sivabakkiyam-logo'
            /> */}
        <img src={icon} className='sivabakkiyam-logo' alt='' />
      </Grid>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid
          container
          display='flex'
          flexDirection='column'
          justifyContent='center'
          className='login-form'
        >
          <Grid justifyContent='center' display={'flex'} my={2}>
            <Typography
              fontSize={24}
              fontWeight={700}
              color={theme.palette.common.white}
            >
              {t('LOGIN_PAGE')}
            </Typography>
          </Grid>
          <Grid my={1}>
            <LabelWithIcon
              label={t('EMAIL_PHONE')}
              // iconProps={{ height: 33 }}
              labelProps={{ color: theme.palette.common.white, fontSize: 18 }}
              justifyContent={'flex-start'}
              // className='dob-input'
            />
          </Grid>
          <Grid item my={1}>
            <FormInput
              placeholder={`${t('EMAIL_PHONE')}.........`}
              className='username-input'
              name='email'
              control={control}
              rules={{
                required: {
                  value: true,
                  message: t('PLEASE_ENTER_A_EMAIL_PHONE'),
                },
              }}
              inputProps={{
                className: 'text-center',
                ...register('email', {}),
              }}
              errors={errors}
            />
          </Grid>

          <Grid my={1}>
            <LabelWithIcon
              label={t('PASSWORD')}
              // iconProps={{ height: 33 }}
              labelProps={{ color: theme.palette.common.white, fontSize: 18 }}
              justifyContent={'flex-start'}
              // className='dob-input'
            />
          </Grid>
          <Grid item my={1}>
            <FormInput
              placeholder={`${t('PASSWORD')}.........`}
              className='username-input'
              name='password'
              type='password'
              control={control}
              rules={{
                required: {
                  value: true,
                  message: t('ENTER_YOUR_PASSWORD'),
                },
              }}
              inputProps={{
                className: 'text-center',
                ...register('password', {}),
              }}
              errors={errors}
            />
          </Grid>
          <Grid justifyContent='flex-end' display={'flex'} my={1}>
            <Link
              to={''}
              onClick={() => {
                handePassword();
              }}
            >
              <Typography color={theme.palette.common.white}>
                {t('FORGET_PASSWORD')}
              </Typography>
            </Link>
          </Grid>
          <Grid justifyContent='center' display={'flex'} mt={1.5} mb={5}>
            <CustomButton
              type='submit'
              text={t('LOGIN')}
              className='login-button'
              color={'primary'}
            />
          </Grid>
        </Grid>
      </form>
    </Grid>
  );
};

export default LoginForm;
