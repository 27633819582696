import React from 'react';
import RouteForm from './RouteForm';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import Header from '../../../common/Header';
import { APP_ROUTES } from '../../../common/constants/Routes';
import SubdirectoryArrowRightIcon from '@mui/icons-material/SubdirectoryArrowRight';

function AddRoute() {
  const { t } = useTranslation();
  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    watch,
    setValue,
  } = useForm({
    defaultValues: {},
  });
  return (
    <div>
      <Header
        header={t('ADD_ROUTE')}
        route={APP_ROUTES.ASSIGNWORK}
        buttonName={t('GO_BACK')}
        buttonIcon={SubdirectoryArrowRightIcon}
        view={true}
      />

      <RouteForm
        register={register}
        errors={errors}
        handleSubmit={handleSubmit}
        watch={watch}
        view={false}
        control={control}
        setValue={setValue}
      />
    </div>
  );
}

export default AddRoute;
