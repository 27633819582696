import { Grid, Typography } from '@mui/material';
import React from 'react';

import { useNavigate } from 'react-router-dom';
import CustomButton from './ui/CustomButton';
function Header({
  header,
  buttonName,
  view = true,
  route,
  buttonIcon,
}: {
  header: any;
  buttonName?: any;
  view: any;
  route?: any;
  buttonIcon?: any;
}) {
  const navigate = useNavigate();
  return (
    <Grid
      display={'flex'}
      justifyContent={'space-between'}
      alignItems={'center'}
    >
      <Typography fontSize={20} fontWeight={700}>
        {header}
      </Typography>
      {view && (
        <CustomButton
          type='button'
          text={buttonName}
          startIcon={buttonIcon}
          className='px-10 py-5 '
          fontSize={14}
          onClick={() => {
            navigate(route, {
              state: { data: { type: 'view' } },
            });
          }}
          color={'secondary'}
        />
      )}
    </Grid>
  );
}

export default Header;
