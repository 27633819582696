import { axiosAuthInstance } from '../../common/axiosInstance';
import ROUTES from '../../common/routes';

const axiosAuth = axiosAuthInstance;

const { loginRequest, signUp, otp, otpVerify, forgetPassword } = ROUTES;

// TODO: uncomment when working on api

const submitLoginRequestInfo = (data: any) =>
  axiosAuth.request({ ...loginRequest, data });
const submitSignUpRequest = (data: any) =>
  axiosAuth.request({ ...signUp, data });
const submitOtpRequest = (data: any) => axiosAuth.request({ ...otp, data });
const submitOtpVerifyRequest = (data: any) =>
  axiosAuth.request({ ...otpVerify, data });
const submitForgetPasswordRequest = (data: any) =>
  axiosAuth.request({ ...forgetPassword, data });

export {
  submitLoginRequestInfo,
  submitSignUpRequest,
  submitOtpRequest,
  submitOtpVerifyRequest,
  submitForgetPasswordRequest,
};
