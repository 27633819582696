import { Grid } from '@mui/material';
import { useForm } from 'react-hook-form';
import React, { useRef, useState } from 'react';

import './Login.scss';
import FormInput from '../../../common/ui/Forms/FormInput/FormInput';
import LabelWithIcon from '../../../common/ui/LabelWithIcon';
import CustomButton from '../../../common/ui/CustomButton';
import image from '../../../common/icons/YAA PETROLEUM.png';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import theme from '../../../common/theme';
import { AUTH_ROUTES } from '../../../common/constants/Routes';
import { forgetPasswordRequest } from '../../../store/auth/_auth.slice';

const ForgetPassword = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const locations = useLocation();

  const [email] = useState(locations?.state?.email || '');
  const emailRef = useRef(locations?.state?.email || '');

  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    watch,
  } = useForm({
    defaultValues: {
      password: '',
      ConfirmPassword: '',
    },
  });

  const onSubmit = (data: any) => {
    const emailToUse = email || emailRef.current;
    data.email = emailToUse;
    console.log(data);
    if (data) {
      const handleSuccess = () => {
        navigate(AUTH_ROUTES.LOGIN, {});
      };
      const payload: any = {
        data,
        navigate,
        handleSuccess,
      };
      dispatch(forgetPasswordRequest(payload));
    }
  };

  // Watch the password field for changes
  const watchPassword = watch('password', '');

  return (
    <Grid className='forget-password'>
      <Grid className='forget-password-form'>
        <Grid justifyContent='center' display={'flex'} my={2}>
          <img src={image} alt='logo' className='img-forget-password' />
        </Grid>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid>
            <Grid my={1}>
              <LabelWithIcon
                label={'New Password'}
                labelProps={{ color: theme.palette.common.black, fontSize: 18 }}
                justifyContent={'flex-start'}
              />
            </Grid>
            <Grid item my={1}>
              <FormInput
                placeholder={`Enter your Password.........`}
                className='username-input'
                name='password'
                type='password'
                control={control}
                rules={{
                  required: {
                    value: true,
                    message: 'Please enter a password',
                  },
                  pattern: {
                    value:
                      /^(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
                    message:
                      'Password must have at least 8 characters, one uppercase letter, one number, and one special character',
                  },
                }}
                inputProps={{
                  className: 'text-center',
                  ...register('password', {}),
                }}
                errors={errors}
              />
            </Grid>

            <Grid my={1}>
              <LabelWithIcon
                label={'Confirm New Password'}
                labelProps={{ color: theme.palette.common.black, fontSize: 18 }}
                justifyContent={'flex-start'}
              />
            </Grid>
            <Grid item my={1}>
              <FormInput
                placeholder={`Confirm your Password.........`}
                className='username-input'
                name='ConfirmPassword'
                type='password'
                control={control}
                rules={{
                  required: {
                    value: true,
                    message: 'Please confirm your password',
                  },
                  validate: (value: any) =>
                    value === watchPassword || 'Passwords do not match',
                }}
                inputProps={{
                  className: 'text-center',
                  ...register('ConfirmPassword', {}),
                }}
                errors={errors}
              />
            </Grid>

            <Grid justifyContent='center' display={'flex'} my={5}>
              <CustomButton
                type='submit'
                text='Submit'
                className='login-button'
                color={'primary'}
              />
            </Grid>
          </Grid>
        </form>
      </Grid>
    </Grid>
  );
};

export default ForgetPassword;
