import React from 'react';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import Header from '../../../common/Header';
import { APP_ROUTES } from '../../../common/constants/Routes';
import SubdirectoryArrowRightIcon from '@mui/icons-material/SubdirectoryArrowRight';
import StoresForm from './StoresForm';
import { useLocation, useNavigate } from 'react-router-dom';

function EditStore() {
  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    watch,
    setValue,
  } = useForm({
    defaultValues: {
      store_name: location.state.data?.data?.store_name || '',
      status: location.state.data?.data?.status || '',
      address: location.state.data?.data?.address || '',
      mobile_number: location.state.data?.data?.mobile_number || '',
      latitude: location.state.data?.data?.latitude || '',
      longitude: location.state.data?.data?.longitude || '',

      store_incharge: location.state.data?.data?.store_incharge || '',
    },
  });
  const onSubmit = (data: any) => {
    console.log(data);
    navigate(APP_ROUTES.STORE);
  };
  return (
    <div>
      <Header
        header={t('EDIT_STORE')}
        route={APP_ROUTES.STORE}
        buttonName={t('GO_BACK')}
        buttonIcon={SubdirectoryArrowRightIcon}
        view={true}
      />

      <StoresForm
        register={register}
        errors={errors}
        handleSubmit={handleSubmit}
        watch={watch}
        control={control}
        setValue={setValue}
        onSubmit={onSubmit}
        view={false}
      />
    </div>
  );
}

export default EditStore;
