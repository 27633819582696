import React from 'react';
import { useTranslation } from 'react-i18next';
import LabelWithIcon from '../../../common/ui/LabelWithIcon';
import CustomButton from '../../../common/ui/CustomButton';
import FormInput from '../../../common/ui/Forms/FormInput/FormInput';
import CustomSelectBox from '../../../common/ui/Selectbox/CustomSelect';
import { vechicelStatus } from '../../../common/constants/dropDownList';
import { Grid } from '@mui/material';

function VechicelFrom({
  handleSubmit,
  register,
  errors,
  view,
  watch,
  control,
  setValue,
  onSubmit,
}: {
  handleSubmit: any;
  register: any;
  errors: any;
  view: any;
  watch: any;
  control: any;
  setValue: any;
  onSubmit: any;
}) {
  const { t } = useTranslation();
  const lock = [
    {
      id: '1',
      lock_no: 'LOCK005',
      lock_name: 'LOCK005',
    },
    {
      id: '2',
      lock_no: 'LOCK008',
      lock_name: 'LOCK005',
    },
    {
      id: '3',
      lock_no: 'LOCK006',
      lock_name: 'LOCK005',
    },
    {
      id: '4',
      lock_no: 'LOCK015',
      lock_name: 'LOCK005',
    },
  ];
  const vechicel_type = [
    {
      id: '1',
      name: 'Mini Truck',
    },
    {
      id: '2',
      name: 'Truck',
    },
  ];
  return (
    <div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container display='flex' justifyContent='flex-start'>
          <Grid item xs={12} sm={6} md={6} lg={6} xl={6} p={2}>
            <LabelWithIcon
              label={t('VECHICEL_NO')}
              justifyContent='flex-start'
              className='mb-6 ml-3'
            />
            <FormInput
              name={`vechicel_no`}
              type='text'
              placeholder={t('VECHICEL_NO')}
              control={control}
              className='input-from'
              readOnly={view}
              inputProps={{
                ...register(`vechicel_no`, {
                  required: {
                    value: true,
                    message: t('ENTER_THE_VECHICEL_NO'),
                  },
                }),
              }}
              errors={errors}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={6} lg={6} xl={6} p={2}>
            <LabelWithIcon
              label={t('VECHICEL_OWNER')}
              justifyContent='flex-start'
              className='mb-6 ml-3'
            />
            <FormInput
              name={`vechicel_owner`}
              type='text'
              placeholder={t('VECHICEL_OWNER')}
              control={control}
              className='input-from'
              readOnly={view}
              inputProps={{
                ...register(`vechicel_owner`, {
                  required: {
                    value: true,
                    message: t('ENTER_THE_VECHICEL_OWNER'),
                  },
                }),
              }}
              errors={errors}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={6} lg={6} xl={6} p={2}>
            <LabelWithIcon
              label={t('LOADING_UNITS')}
              justifyContent='flex-start'
              className='mb-6 ml-3'
            />
            <FormInput
              name={`loading_units`}
              type='text'
              placeholder={t('LOADING_UNITS')}
              control={control}
              className='input-from'
              readOnly={view}
              inputProps={{
                ...register(`loading_units`, {
                  required: {
                    value: true,
                    message: t('ENTER_THE_LOADING_UNITS'),
                  },
                }),
              }}
              errors={errors}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={6} lg={6} xl={6} p={2}>
            <LabelWithIcon
              label={t('VECHICEL_TYPE')}
              justifyContent='flex-start'
              className='mb-6 ml-3'
            />
            <CustomSelectBox
              options={vechicel_type.map((lock) => ({
                Code: lock.id,
                Description: lock.name,
              }))}
              ClassName='form-select'
              placeholder={t('VECHICEL_TYPE')}
              inputProps={{
                ...register('vechicel_type', {
                  required: {
                    value: true,
                    message: t('ENTER_THE_VECHICEL_TYPE'),
                  },
                }),
              }}
              name='vechicel_type'
              error={errors}
              readOnly={view}
              value={watch('vechicel_type')}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={6} lg={6} xl={6} p={2}>
            <LabelWithIcon
              label={t('LOCK')}
              justifyContent='flex-start'
              className='mb-6 '
            />
            <CustomSelectBox
              options={lock.map((lock) => ({
                Code: lock.id,
                Description: `${lock.lock_no} - ${lock.lock_name}`,
              }))}
              ClassName='form-select'
              placeholder={t('LOCK')}
              inputProps={{
                ...register('lock_id', {
                  required: {
                    value: true,
                    message: t('SELECT_THE_LOCK'),
                  },
                }),
              }}
              name='lock_id'
              error={errors}
              readOnly={view}
              value={watch('lock_id')}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={6} lg={6} xl={6} p={2}>
            <LabelWithIcon
              label={t('STATUS')}
              justifyContent='flex-start'
              className='mb-6 ml-3'
            />
            <CustomSelectBox
              options={vechicelStatus}
              ClassName='form-select'
              placeholder={t('STATUS')}
              inputProps={register(`status`, {
                required: {
                  value: true,
                  message: t('SELECT_THE_STATUS'),
                },
              })}
              name={'status'}
              error={errors}
              readOnly={view}
              value={watch(`status`)}
            />
          </Grid>
          {view ? null : (
            <Grid item xs={12} p={2}>
              <CustomButton
                text={t('SUBMIT')}
                color='info'
                className='p-5'
                type='submit'
              />
            </Grid>
          )}
        </Grid>
      </form>
    </div>
  );
}
export default VechicelFrom;
