export const status = [
  {
    Code: 1,
    Description: 'Active',
    Category: 'Active',
  },
  {
    Code: 2,
    Description: 'Active',
    Category: 'Active',
  },
];

export const locations = [
  {
    id: 1,
    name: 'HUB',
    location: 'HUB',
    locations: '78.19253131836622,11.457329258303188',
  },
  {
    id: 2,
    name: 'Store 1',
    location: 'Salem',
    locations: '78.14726180962512,11.624903986888828',
  },
  {
    id: 3,
    name: 'Store 2',
    location: 'Gate',
    locations: '78.1579613677564,11.445514946938069',
  },
  {
    id: 4,
    name: 'Store 3',
    location: 'Mallur',
    locations: '78.14259754738184,11.543459793167905',
  },
];

export const vechicelStatus = [
  {
    Code: 1,
    Description: 'ACTIVE',
    Category: 'ACTIVE',
  },
  {
    Code: 2,
    Description: 'IN ACTIVE',
    Category: 'IN ACTIVE',
  },
];

export const vechicelType = [
  {
    id: '1',
    name: 'Mini Truck',
  },
  {
    id: '2',
    name: 'Truck',
  },
];

export const dataset = [
  {
    seoul: 921,
    month: 'Jan',
  },
  {
    seoul: 928,
    month: 'Feb',
  },
  {
    seoul: 500,
    month: 'Mar',
  },
  {
    seoul: 873,
    month: 'Apr',
  },
  {
    seoul: 599,
    month: 'May',
  },
  {
    seoul: 144,
    month: 'June',
  },
  {
    seoul: 319,
    month: 'July',
  },
  {
    seoul: 249,
    month: 'Aug',
  },
  {
    seoul: 600,
    month: 'Sept',
  },
  {
    seoul: 700,
    month: 'Oct',
  },
  {
    seoul: 450,
    month: 'Nov',
  },
  {
    seoul: 700,
    month: 'Dec',
  },
];
// export default { bloodGroup, gender };
