import React, { useEffect } from 'react';
import LabelWithIcon from '../../../common/ui/LabelWithIcon';
import { Grid } from '@mui/material';
import { locations } from '../../../common/constants/dropDownList';
import CustomButton from '../../../common/ui/CustomButton';
import CloseIcon from '@mui/icons-material/Close';
import SearchSelect from '../../../common/ui/Selectbox/SearchSelect';
import { useTranslation } from 'react-i18next';
import { toTime } from '../../../common/utils';
const LocationDropDown = ({
  control,
  errors,
  setValue,
  index,
  register,
  remove,
  watch,
  view,
  field,
  setSelectLocations,
  stop,
}: {
  control: any;
  errors: any;
  setValue: any;
  index: any;
  register: any;
  remove: any;
  watch: any;
  view: any;
  field: any;
  setSelectLocations: any;
  stop: boolean;
}) => {
  const { t } = useTranslation();
  const addLocations = watch('location');

  //Filter out elements from mainArray that are present in secondaryArray
  const filteredLocations = view
    ? locations
    : stop
    ? [locations[0]]
    : [
        locations[0],
        ...locations.filter(
          (mainItem) =>
            !addLocations.some((secItem: any) => mainItem.id === secItem.route)
        ),
      ];

  console.log('filteredLocations', filteredLocations, locations[0]);
  const selectedRoute = watch(`location.[${index}].route`) || null;

  useEffect(() => {
    const selectedLocation = locations.find(
      (option) => option.id === selectedRoute
    );

    const urllocation = `${watch(`location.[${index - 1}].location`)};${
      selectedLocation?.locations
    }`;
    const apicall =
      watch(`location.[${index - 1}].location`) && selectedLocation?.locations
        ? true
        : false;
    const fetchData = async () => {
      try {
        // Make your API call here
        if (index !== 0 && apicall) {
          const response = await fetch(
            `https://apis.mapmyindia.com/advancedmaps/v1/a12d9dceb4a2357a244ebf3857aee533/distance_matrix_eta/driving/${urllocation}?rtype=1&region=ind`,
            {
              method: 'GET', // or POST, depending on your API
              headers: {
                'Content-Type': 'application/json',
                accept: '*/*',
                'Access-Control-Allow-Origin': 'http://localhost:3001',
              },
              credentials: 'include',
              mode: 'no-cors',
            }
          );
          if (!response.ok) {
            throw new Error('Network response was not ok');
          }

          const data = await response.json();
          const distances = (data?.results?.distances[0][1] / 1000).toFixed(1);
          const durations = data?.results?.durations[0][1];
          // Use the API data to update your state
          if (selectedLocation) {
            setValue(
              `location.[${index}].location_name`,
              selectedLocation.location
            );
            setValue(`location.[${index}].km`, distances);
            setValue(`location.[${index}].time`, toTime(durations));
            setValue(
              `location.[${index}].location`,
              selectedLocation.locations
            );
            setValue(
              `location.[${index}].previous_location`,
              index === 0
                ? null
                : watch(`location.[${index - 1}].location`) || null
            );
            setSelectLocations(true);
          }
          console.log('API data:', data);
        } else if (index === 0) {
          if (selectedLocation) {
            setValue(
              `location.[${index}].location_name`,
              selectedLocation.location
            );
            setValue(`location.[${index}].km`, 0);
            setValue(`location.[${index}].time`, toTime(0));
            setValue(
              `location.[${index}].location`,
              selectedLocation.locations
            );
            setValue(
              `location.[${index}].previous_location`,
              index === 0
                ? null
                : watch(`location.[${index - 1}].location`) || null
            );
            setSelectLocations(true);
          }
        }

        // Update any other state or perform other actions based on the API data
      } catch (error) {
        console.error('Failed to fetch data:', error);
      }
    };

    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedRoute, setValue, index, watch]);

  return (
    <>
      <Grid item xs={12} pt={2}>
        <LabelWithIcon
          label={`${t('STORE')} ${index + 1}`}
          justifyContent='flex-start'
          className='mb-6 '
        />
        <Grid display={'flex'}>
          <SearchSelect
            options={filteredLocations.map((lock) => ({
              Code: lock?.id,
              Description: `${lock?.name} - ${lock?.location}`,
            }))}
            ClassName='form-select'
            placeholder={t('STORE')}
            inputProps={{
              ...register(`location.[${index}].route`, {
                required: {
                  value: true,
                  message: t('SELECT_THE_STORE'),
                },
              }),
            }}
            name={`route`}
            error={errors?.location?.[index]}
            readOnly={view}
            inputValue={
              locations.find(
                (val) => val.id === watch(`location.[${index}].route`)
              )?.name
            }
            value={
              locations.find(
                (option) => option.id === watch(`location.[${index}].route`)
              )?.id
            }
            setSelectedOption={(selectedValue) => {
              // Update the form value when a selection is made
              register(`location.[${index}].route`).onChange({
                target: {
                  name: `location.[${index}].route`,
                  value: selectedValue?.Code || '',
                },
              });
            }}
          />
          {/* <input
            hidden
            key={field.id} // important to include key with field's id
            {...register(`location.${index}.location_name`)}
            value={
              locations.find((option) => option.id === selectedRoute)?.name ||
              ''
            }
            readOnly
          /> */}
          {view ? null : (
            <CustomButton
              startIcon={CloseIcon}
              type='button'
              text={''}
              className='custom-button'
              onClick={() => {
                remove(index);
              }}
            />
          )}
        </Grid>
      </Grid>
    </>
  );
};

export default LocationDropDown;
