import React from 'react';
import RouteForm from './RouteForm';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import Header from '../../../common/Header';
import { APP_ROUTES } from '../../../common/constants/Routes';
import SubdirectoryArrowRightIcon from '@mui/icons-material/SubdirectoryArrowRight';
import { useLocation } from 'react-router-dom';
import { Grid, Typography } from '@mui/material';

function ViewRoute() {
  const { t } = useTranslation();
  const location = useLocation();
  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    watch,
    setValue,
  } = useForm({
    defaultValues: {
      vechicle: location.state.data?.data?.vechicle_id || '',
      lock: location.state.data?.data?.lock_id || '',
      location: location.state.data?.data?.location || '',
    },
  });
  const cancel = location.state.data?.data?.status === 'Cancel';

  return (
    <div>
      <Header
        header={t('VIEW_ROUTE')}
        route={APP_ROUTES.ASSIGNWORK}
        buttonName={t('GO_BACK')}
        buttonIcon={SubdirectoryArrowRightIcon}
        view={true}
      />
      {cancel && (
        <Grid px={2} my={2}>
          <Typography color={'error'}>
            NOTE : This Route Was cancled for worngly assigned
          </Typography>
        </Grid>
      )}

      <RouteForm
        register={register}
        errors={errors}
        handleSubmit={handleSubmit}
        watch={watch}
        view={true}
        control={control}
        setValue={setValue}
      />
    </div>
  );
}

export default ViewRoute;
