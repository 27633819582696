export const AUTH_ROUTES = {
  LOGIN: '/',
  LOGIN_OTP_VERIFY: '/login/otp-verify',
  FORGET_PASSWORD: '/forgetPassword',
};

export const APP_ROUTES = {
  DASHBOARD: '/',
  CHANGEPASSWORD: '/changepassword',
  PROFILE: '/profile',
  ASSIGNWORK: '/assignwork',
  ASSIGNWORKADD: '/assignwork/add',
  ASSIGNWORKVIEW: '/assignwork/view',
  VEHICLE: '/vehicle',
  VEHICLEADD: '/vehicle/add',
  VEHICLEEDIT: '/vehicle/edit',
  VEHICLEVIEW: '/vehicle/view',
  STORE: '/store',
  STOREADD: '/store/add',
  STOREEDit: '/store/edit',
  STOREVIEW: '/store/view',
  LOCK: '/lock',
  LOCKADD: '/lock/add',
  LOCKEDit: '/lock/edit',
  LOCKVIEW: '/lock/view',
};

export const PUBLIC_ROUTES = {};
