import { Grid } from '@mui/material';
import React from 'react';
import EditProfile from './EditProfile';
import { useForm } from 'react-hook-form';

const ProfileScreen = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    watch,
    setValue,

    reset,
  } = useForm({
    defaultValues: {},
  });

  return (
    <div>
      <Grid>
        <EditProfile
          handleSubmit={handleSubmit}
          text={'Profile'}
          register={register}
          control={control}
          errors={errors}
          reset={reset}
          setValue={setValue}
          watch={watch}
        />
      </Grid>
    </div>
  );
};

export default ProfileScreen;
