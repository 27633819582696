import React from 'react';
import { useTranslation } from 'react-i18next';
import LabelWithIcon from '../../../common/ui/LabelWithIcon';
import CustomButton from '../../../common/ui/CustomButton';
import FormInput from '../../../common/ui/Forms/FormInput/FormInput';
import CustomSelectBox from '../../../common/ui/Selectbox/CustomSelect';
import { vechicelStatus } from '../../../common/constants/dropDownList';
import { Grid } from '@mui/material';

function StoresForm({
  handleSubmit,
  register,
  errors,
  view,
  watch,
  control,
  setValue,
  onSubmit,
}: {
  handleSubmit: any;
  register: any;
  errors: any;
  view: any;
  watch: any;
  control: any;
  setValue: any;
  onSubmit: any;
}) {
  const { t } = useTranslation();

  return (
    <div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container display='flex' justifyContent='flex-start'>
          <Grid item xs={12} sm={6} md={6} lg={6} xl={6} p={2}>
            <LabelWithIcon
              label={t('STORE_NAME')}
              justifyContent='flex-start'
              className='mb-6 ml-3'
            />
            <FormInput
              name={`store_name`}
              type='text'
              placeholder={t('STORE_NAME')}
              control={control}
              className='input-from'
              readOnly={view}
              inputProps={{
                ...register(`store_name`, {
                  required: {
                    value: true,
                    message: t('ENTER_THE_STORE_NAME'),
                  },
                }),
              }}
              errors={errors}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={6} lg={6} xl={6} p={2}>
            <LabelWithIcon
              label={t('STORE_INCHARGE')}
              justifyContent='flex-start'
              className='mb-6 ml-3'
            />
            <FormInput
              name={`store_incharge`}
              type='text'
              placeholder={t('STORE_INCHARGE')}
              control={control}
              className='input-from'
              readOnly={view}
              inputProps={{
                ...register(`store_incharge`, {
                  required: {
                    value: true,
                    message: t('ENTER_THE_INCHARGE_NAME'),
                  },
                }),
              }}
              errors={errors}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={6} lg={6} xl={6} p={2}>
            <LabelWithIcon
              label={t('LATITUDE')}
              justifyContent='flex-start'
              className='mb-6 ml-3'
            />
            <FormInput
              name={`latitude`}
              type='text'
              placeholder={t('LATITUDE')}
              control={control}
              className='input-from'
              readOnly={view}
              inputProps={{
                ...register(`latitude`, {
                  required: {
                    value: true,
                    message: t('ENTER_THE_LATITUDE'),
                  },
                  pattern: {
                    value: /^[0-9.]*$/,
                    message: t('ONLY_NUMERIC_CHARACTERS_ALLOWED'),
                  },
                }),
              }}
              errors={errors}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={6} lg={6} xl={6} p={2}>
            <LabelWithIcon
              label={t('LONGITUDE')}
              justifyContent='flex-start'
              className='mb-6 ml-3'
            />
            <FormInput
              name={`longitude`}
              type='text'
              placeholder={t('LONGITUDE')}
              control={control}
              className='input-from'
              readOnly={view}
              inputProps={{
                ...register(`longitude`, {
                  required: {
                    value: true,
                    message: t('ENTER_THE_LONGITUDE'),
                  },
                  pattern: {
                    value: /^[0-9.]*$/,
                    message: t('ONLY_NUMERIC_CHARACTERS_ALLOWED'),
                  },
                }),
              }}
              errors={errors}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={6} lg={6} xl={6} p={2}>
            <LabelWithIcon
              label={t('MOBILE_NUMBER')}
              justifyContent='flex-start'
              className='mb-6 ml-3'
            />
            <FormInput
              name={`mobile_number`}
              type='text'
              placeholder={t('MOBILE_NUMBER')}
              control={control}
              className='input-from'
              readOnly={view}
              inputProps={{
                ...register(`mobile_number`, {
                  required: {
                    value: true,
                    message: t('ENTER_THE_MOBILE_NUMBER'),
                  },
                  pattern: {
                    value: /^[6789]\d{9}$/,
                    message: t('ENTER_A_VALID_10_DIGIT_MOBILE_NUMBER'),
                  },
                  validate: {
                    onlyNumeric: (value: any) =>
                      /^[0-9]*$/.test(value) ||
                      t('ONLY_NUMERIC_CHARACTERS_ALLOWED'),
                  },
                }),
              }}
              errors={errors}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={6} lg={6} xl={6} p={2}>
            <LabelWithIcon
              label={t('ALTER_NUMBER')}
              justifyContent='flex-start'
              className='mb-6 ml-3'
            />
            <FormInput
              name={`alternate_number`}
              type='text'
              placeholder={t('ALTER_NUMBER')}
              control={control}
              className='input-from'
              readOnly={view}
              inputProps={{
                ...register(`alternate_number`, {
                  pattern: {
                    value: /^[6789]\d{9}$/,
                    message: t('ENTER_A_VALID_10_DIGIT_MOBILE_NUMBER'),
                  },
                  validate: {
                    onlyNumeric: (value: any) =>
                      /^[0-9]*$/.test(value) ||
                      t('ONLY_NUMERIC_CHARACTERS_ALLOWED'),
                  },
                }),
              }}
              errors={errors}
            />
          </Grid>

          <Grid item xs={12} sm={6} md={6} lg={6} xl={6} p={2}>
            <LabelWithIcon
              label={t('STATUS')}
              justifyContent='flex-start'
              className='mb-6 ml-3'
            />
            <CustomSelectBox
              options={vechicelStatus}
              ClassName='form-select'
              placeholder={t('STATUS')}
              inputProps={register(`status`, {
                required: {
                  value: true,
                  message: t('SELECT_THE_STATUS'),
                },
              })}
              name={'status'}
              error={errors}
              readOnly={view}
              value={watch(`status`)}
            />
          </Grid>

          <Grid item xs={12} sm={6} md={6} lg={6} xl={6} p={2}>
            <LabelWithIcon
              label={t('ADDRESS')}
              justifyContent='flex-start'
              className='mb-6 '
            />
            <FormInput
              hideCountButton
              multipleLine
              name='address'
              type='text'
              placeholder='address..'
              control={control}
              className='input-from-text'
              rules={{
                required: {
                  value: true,
                  message: t('ENTER_THE_ADDRESS'),
                },
              }}
              inputProps={{
                ...register('address', {}),
              }}
              errors={errors}
            />
          </Grid>
          {view ? null : (
            <Grid item xs={12} p={2}>
              <CustomButton
                text={t('SUBMIT')}
                color='info'
                className='p-5'
                type='submit'
              />
            </Grid>
          )}
        </Grid>
      </form>
    </div>
  );
}

export default StoresForm;
