/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-console */
/* eslint-disable camelcase */
/* eslint-disable import/no-extraneous-dependencies */
import React, { useEffect, useMemo } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';

import AuthRoutes from '../Layout/AuthRoutes';
import AppRoutes from '../Layout/AppRoutes';
import theme, { changeCssColors } from '../common/theme';
import './colors.css';
import './App.scss';
import { ThemeProvider, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Provider } from 'react-redux';
import store from '../store/store';
import ApiModal from '../common/ui/ApiModal';

const App = () => {
  const themes = useTheme();
  useTranslation();
  useEffect(() => {
    changeCssColors(themes);
  }, [themes]);

  const isLogged = useMemo(
    () => !!localStorage.getItem('user'),
    [localStorage.getItem('user')]
  );

  return (
    <>
      <Provider store={store}>
        <ThemeProvider theme={theme}>
          <Router>
            <AuthRoutes isLogged={isLogged} />
            <AppRoutes isLogged={isLogged} />
          </Router>
          <ApiModal />
        </ThemeProvider>
      </Provider>
    </>
  );
};

export default App;
