import React from 'react';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import CircularProgress from '@mui/material/CircularProgress';
import FormHelperText from '@mui/material/FormHelperText';
import { useTheme } from '@mui/material/styles';

interface Props {
  label?: string | undefined;
  options: any[];
  value?: any | '';
  setSelectedOption?: (value: any) => void;
  ClassName?: string | undefined;
  dropdownWidth?: string;
  inputProps?: any;
  defaultValue?: any;
  loading?: boolean;
  placeholder?: string;
  error: any;
  errorTextClassName?: string;
  name: string;
  readOnly?: boolean;
  inputValue?: any;
}

const SearchSelect: React.FC<Props> = ({
  label,
  options = [],
  value,
  setSelectedOption,
  ClassName,
  dropdownWidth,
  inputProps,
  defaultValue,
  loading = false,
  placeholder,
  error,
  errorTextClassName,
  name,
  readOnly = false,
  inputValue,
}) => {
  const theme = useTheme();

  const handleChange = (event: any, newValue: any) => {
    if (setSelectedOption) {
      setSelectedOption(newValue);
    }
  };
  console.log('value', inputValue);
  return loading ? (
    <CircularProgress />
  ) : (
    <FormControl className={ClassName} sx={{ width: '100%', padding: 0 }}>
      <Autocomplete
        freeSolo
        value={value?.code || value?.id || value}
        inputValue={options.find((val) => val.Code === value)?.Description}
        onChange={(event, newValue) => {
          handleChange(event, newValue);
          if (inputProps?.onChange) {
            inputProps.onChange({
              target: {
                name: inputProps.name,
                value: newValue?.Code || '',
              },
            });
          }
        }}
        options={options}
        getOptionLabel={(option) => option?.Description || ''}
        isOptionEqualToValue={(option, value) =>
          option?.Code === value?.code || value?.id || value
        }
        readOnly={readOnly}
        renderInput={(params) => (
          <TextField
            name={name}
            {...params}
            placeholder={placeholder}
            variant='outlined'
            error={!!error?.[name]?.message}
            sx={{ padding: 0 }}
          />
        )}
        sx={{ width: '100%' }}
      />
      {error?.[name]?.message && (
        <FormHelperText
          className={`error-text ${errorTextClassName}`}
          error={false}
          sx={{ color: theme.palette.error.dark }}
        >
          {error?.[name]?.message}
        </FormHelperText>
      )}
    </FormControl>
  );
};

export default SearchSelect;
