import { Grid } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import LabelWithIcon from '../../../common/ui/LabelWithIcon';
import SearchSelect from '../../../common/ui/Selectbox/SearchSelect';
import TimeLine from '../../../common/ui/TimeLine';
import CustomButton from '../../../common/ui/CustomButton';
import { useFieldArray } from 'react-hook-form';
import LocationDropDown from './LocationDropDown';
import { useNavigate } from 'react-router-dom';
import { toTime } from '../../../common/utils';
import { locations } from '../../../common/constants/dropDownList';
import { APP_ROUTES } from '../../../common/constants/Routes';

function RouteForm({
  handleSubmit,
  register,
  errors,
  view,
  watch,
  control,
  setValue,
}: {
  handleSubmit: any;
  register: any;
  errors: any;
  view: any;
  watch: any;
  control: any;
  setValue: any;
}) {
  // const locationss = useLocation();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [stop, setStop] = useState(false);
  const [selectLocations, setSelectLocations] = useState(false);
  const [location, setLocations] = useState([]);
  const onSubmit = (data: any) => {
    console.log('Form Data:', data);
    // Handle form submission logic here
    navigate(APP_ROUTES.ASSIGNWORK);
  };

  const tank = [
    {
      id: '1',
      vechicle_no: 'TN28B0523',
      vechicle_name: 'KKN',
    },
    {
      id: '2',
      vechicle_no: 'TN26B0863',
      vechicle_name: 'MARI',
    },
    {
      id: '3',
      vechicle_no: 'TN28D9652',
      vechicle_name: 'SRI',
    },
    {
      id: '4',
      vechicle_no: 'TN28B0523',
      vechicle_name: 'RAJA',
    },
  ];
  const lock = [
    {
      id: '1',
      lock_no: 'LOCK005',
      lock_name: 'LOCK005',
    },
    {
      id: '2',
      lock_no: 'LOCK008',
      lock_name: 'LOCK005',
    },
    {
      id: '3',
      lock_no: 'LOCK006',
      lock_name: 'LOCK005',
    },
    {
      id: '4',
      lock_no: 'LOCK015',
      lock_name: 'LOCK005',
    },
  ];

  // const Location1 = watch('location') || [];

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'location',
  });
  const handleAddRoute = () => {
    remove();
    setStop(false);
    const familyMembers: any = {
      previous_location: 'null',
      route: locations[0]?.id,
      km: '0',
      location_name: locations[0].location,
      location: locations[0].locations,
      time: toTime(0),
    };
    append(familyMembers);
  };
  const handleStop = () => {
    const familyMembers: any = {
      previous_location: '',
      route: '',
      km: '',
      location_name: '',
    };
    append(familyMembers);
  };
  const handleEnd = () => {
    setStop(true);
    const familyMembers: any = {
      previous_location: '',
      route: '',
      km: '',
      location_name: '',
    };
    append(familyMembers);
  };
  // const Locations = locationss.state.data?.data?.location || [
  //   { location_name: 'HUB', km: '0' },
  //   { location_name: 'Gate', km: '8' },
  //   { location_name: 'Mullur', km: '12' },
  //   { location_name: 'Salem', km: '12' },
  //   { location_name: 'HUB', km: '24' },
  // ];
  useEffect(() => {
    const subscription = watch((value: { location: any }) => {
      setLocations(value.location || []);
    });
    setSelectLocations(false);
    return () => subscription.unsubscribe();
  }, [watch, selectLocations]);

  return (
    <div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container display='flex' justifyContent='flex-start'>
          <Grid item xs={12} sm={6} md={6} lg={6} xl={6} p={2}>
            <LabelWithIcon
              label={t('VECHICLE')}
              justifyContent='flex-start'
              className='mb-6 '
            />
            <SearchSelect
              options={tank.map((tank) => ({
                Code: tank.id,
                Description: `${tank.vechicle_no} - ${tank.vechicle_name}`,
              }))}
              ClassName='form-select'
              placeholder={t('VECHICLE')}
              inputProps={{
                ...register('vechicle', {
                  required: {
                    value: true,
                    message: t('SELECT_THE_VECHICEL'),
                  },
                }),
              }}
              name='vechicle'
              error={errors}
              readOnly={view}
              value={watch('vechicle')}
              setSelectedOption={(selectedValue) => {
                if (!view) {
                  register('vechicle').onChange({
                    target: {
                      name: 'vechicle',
                      value: selectedValue?.Code || '',
                    },
                  });
                }
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={6} lg={6} xl={6} p={2}>
            <LabelWithIcon
              label={t('LOCK')}
              justifyContent='flex-start'
              className='mb-6 '
            />
            <SearchSelect
              options={lock.map((lock) => ({
                Code: lock.id,
                Description: `${lock.lock_no} - ${lock.lock_name}`,
              }))}
              ClassName='form-select'
              placeholder={t('LOCK')}
              inputProps={{
                ...register('lock', {
                  required: {
                    value: true,
                    message: t('SELECT_THE_LOCK'),
                  },
                }),
              }}
              name='lock'
              error={errors}
              readOnly={view}
              value={watch('lock')}
              setSelectedOption={(selectedValue) => {
                if (!view) {
                  register('lock').onChange({
                    target: {
                      name: 'lock',
                      value: selectedValue?.Code || '',
                    },
                  });
                }
              }}
            />
          </Grid>
          <Grid item xs={6} p={2}>
            <CustomButton
              text={t('START')}
              className='p-5'
              onClick={handleAddRoute}
              disabled={view}
            />
            <CustomButton
              text={t('ADD_STOP')}
              color='info'
              className='p-5 mx-10'
              onClick={handleStop}
              disabled={view || stop || fields.length === 0}
            />
            <CustomButton
              text={t('END')}
              color='error'
              className='p-5'
              onClick={handleEnd}
              disabled={view || stop || fields.length === 0}
            />
            {fields.map((item: any, index: number) => (
              <>
                <LocationDropDown
                  control={control}
                  remove={remove}
                  index={index}
                  errors={errors}
                  register={register}
                  watch={watch}
                  view={view}
                  field={item}
                  setValue={setValue}
                  setSelectLocations={setSelectLocations}
                  stop={stop}
                />
              </>
            ))}
          </Grid>
          <Grid item xs={6}>
            <TimeLine location={location} />
          </Grid>
          <Grid item xs={6} p={2}>
            {view ? null : (
              <CustomButton
                text={t('SUBMIT')}
                color='error'
                className='p-5'
                type='submit'
                disabled={stop === false}
              />
            )}
          </Grid>
        </Grid>
      </form>
    </div>
  );
}

export default RouteForm;
