import React from 'react';
import { useTranslation } from 'react-i18next';
import LabelWithIcon from '../../../common/ui/LabelWithIcon';
import CustomButton from '../../../common/ui/CustomButton';
import FormInput from '../../../common/ui/Forms/FormInput/FormInput';
import CustomSelectBox from '../../../common/ui/Selectbox/CustomSelect';
import { vechicelStatus } from '../../../common/constants/dropDownList';
import { Grid } from '@mui/material';

function LockForm({
  handleSubmit,
  register,
  errors,
  view,
  watch,
  control,
  setValue,
  onSubmit,
}: {
  handleSubmit: any;
  register: any;
  errors: any;
  view: any;
  watch: any;
  control: any;
  setValue: any;
  onSubmit: any;
}) {
  const { t } = useTranslation();

  return (
    <div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container display='flex' justifyContent='flex-start'>
          <Grid item xs={12} sm={6} md={6} lg={6} xl={6} p={2}>
            <LabelWithIcon
              label={t('LOCK_NAME')}
              justifyContent='flex-start'
              className='mb-6 ml-3'
            />
            <FormInput
              name={`lock_name`}
              type='text'
              placeholder={t('LOCK_NAME')}
              control={control}
              className='input-from'
              readOnly={view}
              inputProps={{
                ...register(`lock_name`, {
                  required: {
                    value: true,
                    message: t('ENTER_THE_LOCK_NAME'),
                  },
                }),
              }}
              errors={errors}
            />
          </Grid>

          <Grid item xs={12} sm={6} md={6} lg={6} xl={6} p={2}>
            <LabelWithIcon
              label={t('STATUS')}
              justifyContent='flex-start'
              className='mb-6 ml-3'
            />
            <CustomSelectBox
              options={vechicelStatus}
              ClassName='form-select'
              placeholder={t('STATUS')}
              inputProps={register(`status`, {
                required: {
                  value: true,
                  message: t('SELECT_THE_STATUS'),
                },
              })}
              name={'status'}
              error={errors}
              readOnly={view}
              value={watch(`status`)}
            />
          </Grid>
          {view ? null : (
            <Grid item xs={12} p={2}>
              <CustomButton
                text={t('SUBMIT')}
                color='info'
                className='p-5'
                type='submit'
              />
            </Grid>
          )}
        </Grid>
      </form>
    </div>
  );
}

export default LockForm;
