import { Grid, Typography, useTheme } from '@mui/material';
import React from 'react';

import LabelWithIcon from '../../../common/ui/LabelWithIcon';
import FormInput from '../../../common/ui/Forms/FormInput/FormInput';
import CustomButton from '../../../common/ui/CustomButton';
import { APP_ROUTES } from '../../../common/constants/Routes';
import { useLocation, useNavigate } from 'react-router-dom';
import SubdirectoryArrowRightIcon from '@mui/icons-material/SubdirectoryArrowRight';
import UserProfileImage from '../../../common/UserProfileImage';
import Person2Icon from '../../../common/icons/profile-png-5.png';

const EditProfile = ({
  text,
  handleSubmit,
  register,
  reset,
  errors,
  control,
  setValue,
  watch,
}: {
  text: any;
  handleSubmit: any;
  register: any;
  reset: any;
  errors: any;
  control: any;
  setValue: any;
  watch: any;
}) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const location = useLocation();
  console.log(errors);
  const onSubmit = (data: any) => {
    console.log(data);
    // console.log(data?.userName);
    navigate(APP_ROUTES.DASHBOARD);
  };
  return (
    <Grid>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid
          container
          display='flex'
          justifyContent='flex-start'
          // className='login-form'
        >
          <Grid container xs={12} p={2}>
            <Grid item xs={6} alignItems={'center'} display={'flex'}>
              <Typography
                fontSize={20}
                fontWeight={600}
                color={theme.palette.info.dark}
              >
                {text}
              </Typography>
            </Grid>
            <Grid
              item
              xs={6}
              alignItems={'center'}
              justifyContent={'flex-end'}
              display={'flex'}
            >
              <CustomButton
                type='button'
                text='Go Back'
                startIcon={SubdirectoryArrowRightIcon}
                color={'primary'}
                className='p-8 color-gray'
                onClick={() => {
                  navigate(APP_ROUTES.DASHBOARD);
                }}
              />
            </Grid>
          </Grid>
          <Grid
            item
            xs={12}
            sm={6}
            md={6}
            lg={6}
            xl={6}
            p={2}
            justifyContent={'flex-start'}
            display={'flex'}
          >
            <UserProfileImage
              control={control}
              register={register}
              errors={errors}
              image={Person2Icon}
              setValue={setValue}
              watch={watch}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={6} lg={6} xl={6} p={2}>
            <LabelWithIcon
              label={'First Name'}
              justifyContent='flex-start'
              className='mb-6  '
            />
            <FormInput
              name='firstName'
              type='text'
              placeholder='First Name..'
              control={control}
              className='input-from'
              rules={{
                required: {
                  value: true,
                  message: 'Please enter a First Name',
                },
              }}
              inputProps={{
                ...register('firstName', {}),
              }}
              errors={errors}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={6} lg={6} xl={6} p={2}>
            <LabelWithIcon
              label={'Last Name'}
              justifyContent='flex-start'
              className='mb-6 '
            />
            <FormInput
              name='lastName'
              type='text'
              placeholder='Last Name..'
              control={control}
              className='input-from'
              rules={{
                required: {
                  value: true,
                  message: 'Please enter a lastName',
                },
              }}
              inputProps={{
                ...register('lastName', {}),
              }}
              errors={errors}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={6} lg={6} xl={6} p={2}>
            <LabelWithIcon
              label={'Email'}
              justifyContent='flex-start'
              className='mb-6 '
            />
            <FormInput
              name='email'
              type='text'
              placeholder='Email..'
              control={control}
              className='input-from'
              rules={{
                required: {
                  value: true,
                  message: 'Please enter a Email',
                },
              }}
              inputProps={{
                ...register('email', {}),
              }}
              errors={errors}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={6} lg={6} xl={6} p={2}>
            <LabelWithIcon
              label={'Mobile number'}
              justifyContent='flex-start'
              className='mb-6 '
            />
            <FormInput
              name='contact'
              type='text'
              placeholder='Mobile..'
              control={control}
              className='input-from'
              rules={{
                required: {
                  value: true,
                  message: 'Please enter a  Mobile number',
                },
              }}
              inputProps={{
                ...register('contact', {}),
              }}
              errors={errors}
            />
          </Grid>

          <Grid item xs={12} sm={6} md={6} lg={6} xl={6} p={2}>
            <LabelWithIcon
              label={'DOB'}
              justifyContent='flex-start'
              className='mb-6 '
            />
            <FormInput
              name='dob'
              type='date'
              placeholder='First Name..'
              control={control}
              className='input-from'
              rules={{
                required: {
                  value: true,
                  message: 'Please enter DOB',
                },
              }}
              inputProps={{
                ...register('dob', {}),
              }}
              errors={errors}
            />
          </Grid>

          <Grid item xs={12} sm={6} md={6} lg={6} xl={6} p={2}>
            <LabelWithIcon
              label={'Address'}
              justifyContent='flex-start'
              className='mb-6 '
            />
            <FormInput
              // hideCountButton
              // multipleLine
              name='address'
              type='text'
              placeholder='address..'
              control={control}
              className='input-from-text'
              rules={{
                required: {
                  value: true,
                  message: 'Please enter a address',
                },
              }}
              inputProps={{
                ...register('address', {}),
              }}
              errors={errors}
            />
          </Grid>
          <Grid
            item
            xs={12}
            sm={6}
            md={6}
            lg={6}
            xl={6}
            p={2}
            justifyContent='flex-end'
            display={'flex'}
            my={5}
          >
            <CustomButton
              type='button'
              text='reset'
              // startIcon={ArrowBackIcon}
              color={'primary'}
              className='p-12 mx-10 color-gray'
              onClick={() => {
                reset();
              }}
            />
            <CustomButton
              type='submit'
              // startIcon={AddIcon}
              text='Update Profile'
              color={'secondary'}
              className='p-12'
            />
          </Grid>
        </Grid>
      </form>
    </Grid>
  );
};

export default EditProfile;
