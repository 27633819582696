import axios, { AxiosInstance, AxiosRequestConfig } from 'axios';
import {
  HandleAxiosRequest,
  HandleAxiosResponse,
  HandleAxiosResponseWithError,
} from './interceptors';
// TODO: add headers
// const selectedLang = localStorage.getItem('i18nextLng');
const token = localStorage.getItem('accessToken');
const axiosAuthConfig: AxiosRequestConfig = {
  baseURL: `http://localhost:7005`,
  headers: {
    'Content-Type': 'application/json',
  },
  method: 'POST',
};
const axiosConfig: AxiosRequestConfig = {
  baseURL: `http://localhost:7005`,
  headers: {
    Authorization: `Bearer ${token}`,
    'Content-Type': 'application/json',
  },
  method: 'POST',
};

const Instance: any = axios.create({
  baseURL: `http://localhost:7005`,
});

const axiosAuthInstance: AxiosInstance = axios.create(axiosAuthConfig);
const axiosInstance: AxiosInstance = axios.create(axiosConfig);

const attachedInterceptors = (axiosInstance: any) => {
  axiosInstance.interceptors.request.use(HandleAxiosRequest, (error: any) =>
    Promise.reject(error)
  );
  axiosInstance.interceptors.response.use(
    HandleAxiosResponse,
    HandleAxiosResponseWithError
  );
};

attachedInterceptors(Instance);
attachedInterceptors(axiosAuthInstance);
attachedInterceptors(axiosInstance);
export { axiosAuthInstance, axiosInstance, Instance };
