import React from 'react';

import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import Header from '../../../common/Header';
import { APP_ROUTES } from '../../../common/constants/Routes';
import SubdirectoryArrowRightIcon from '@mui/icons-material/SubdirectoryArrowRight';
import StoresForm from './StoresForm';
import { useNavigate } from 'react-router-dom';

function AddStores() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    watch,
    setValue,
  } = useForm({
    defaultValues: {},
  });
  const onSubmit = (data: any) => {
    console.log(data);
    navigate(APP_ROUTES.STORE);
  };
  return (
    <div>
      <Header
        header={t('ADD_STORE')}
        route={APP_ROUTES.STORE}
        buttonName={t('GO_BACK')}
        buttonIcon={SubdirectoryArrowRightIcon}
        view={true}
      />

      <StoresForm
        register={register}
        errors={errors}
        handleSubmit={handleSubmit}
        watch={watch}
        view={false}
        control={control}
        setValue={setValue}
        onSubmit={onSubmit}
      />
    </div>
  );
}

export default AddStores;
