import React from 'react';
import { Grid, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import IconButton from '@mui/material/IconButton';
// import { ReactComponent as MenuIcon } from '../../common/icons/menu.svg';
import MenuIcon from '@mui/icons-material/Menu';
import Person2Icon from '../../common/icons/profile-png-5.png';
import LogoIcon from '../../common/icons/YAA PETROLEUM.png';
import Separator from '../../common/ui/Separator';
import PowerSettingsNewIcon from '@mui/icons-material/PowerSettingsNew';
import { Link, useNavigate } from 'react-router-dom';
import { APP_ROUTES } from '../../common/constants/Routes';

const TopHeader = ({
  setDrawerOpen = () => {},
  setNotificationDrawer = () => {},
  contentHeight = 0,
}: {
  setDrawerOpen?: Function;
  setNotificationDrawer?: Function;
  contentHeight?: number | undefined;
}) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const handleLogOff = () => {
    localStorage.removeItem('user');
    navigate(APP_ROUTES.DASHBOARD);
    window.location.reload();
  };
  return (
    <Grid container className='dashBoard-header-menu '>
      <Grid
        item
        className='mr-10 ml-15 menu-bar'
        display={'flex'}
        alignItems={'center'}
      >
        <IconButton
          // color='inherit'
          aria-label='open drawer'
          onClick={() => setDrawerOpen(true)}
          edge='start'
        >
          <MenuIcon />
        </IconButton>
      </Grid>
      <Grid item flex={1} className='mr-20  '>
        <Grid
          item
          xs={12}
          justifyContent={'space-between '}
          className='header-profile'
          alignItems={'center'}
        >
          <Grid className='logo-icon mt-5 '>
            <img src={LogoIcon} alt='logo' className='img-logo' />
          </Grid>

          <Grid
            item
            display={'flex'}
            justifyContent={'center'}
            alignItems={'center'}
            className='header'
          >
            <Grid className='profile-img mt-5 mr-20'>
              <img src={Person2Icon} alt='logo' className='img-logo' />
            </Grid>

            <Grid>
              <Typography
                color={theme.palette.common.black}
                display={'flex'}
                fontSize={14}
                whiteSpace={'nowrap'}
              >
                GEO-LOCATIONS{' '}
                <Typography
                  color={theme.palette.common.black}
                  fontStyle={'italic'}
                  fontSize={14}
                >
                  ( Admin )
                </Typography>
              </Typography>

              <Link to={'#'} onClick={handleLogOff}>
                <Typography
                  display={'flex'}
                  alignItems={'center'}
                  color={theme.palette.grey[600]}
                  fontSize={14}
                >
                  log out &nbsp; <PowerSettingsNewIcon className='f-15 mt-2' />
                </Typography>
              </Link>
            </Grid>
          </Grid>
        </Grid>
        <Separator color={theme.palette.primary.main} />
      </Grid>
    </Grid>
  );
};

export default TopHeader;
