import React from 'react';
import Header from '../../../common/Header';
import { useTranslation } from 'react-i18next';
import { APP_ROUTES } from '../../../common/constants/Routes';
import AddIcon from '@mui/icons-material/Add';
import { Grid } from '@mui/material';
import CircularIndeterminate from '../../../common/CircularLoading';
import Table from '../../../common/Table';
import { GridColDef } from '@mui/x-data-grid';
import { useNavigate } from 'react-router-dom';
import CustomButton from '../../../common/ui/CustomButton';
import {
  vechicelStatus,
  vechicelType,
} from '../../../common/constants/dropDownList';

function Vehicel() {
  const { t } = useTranslation();
  const loading = false;
  const navigate = useNavigate();

  const columns: GridColDef[] = [
    {
      headerName: t('VECHICLE'),
      field: 'vechicel_no',
      sortable: false,
      disableColumnMenu: true,
      flex: 1,
    },
    {
      headerName: t('OWNER'),
      field: 'vechicel_owner',
      sortable: false,
      disableColumnMenu: true,
      flex: 1,
    },
    {
      headerName: t('LOADING_UNITS'),
      field: 'loading_units',
      sortable: false,
      disableColumnMenu: true,
      flex: 1,
    },
    {
      headerName: t('VECHICEL_TYPE'),
      field: 'vechicel_type',
      sortable: false,
      disableColumnMenu: true,
      flex: 1,
      renderCell: ({ row }: any) =>
        vechicelType.find(
          (data: any) => Number(data.id) === Number(row?.vechicel_type)
        )?.name,
    },
    {
      headerName: t('VECHICEL_STATUS'),
      field: 'vechicel_status',
      sortable: false,
      disableColumnMenu: true,
      flex: 1,
    },
    {
      headerName: t('STATUS'),
      field: 'status',
      sortable: false,
      disableColumnMenu: true,
      flex: 1,
      renderCell: ({ row }: any) =>
        vechicelStatus.find(
          (data: any) => Number(data.Code) === Number(row?.status)
        )?.Category,
    },
    {
      headerName: t('ACTION'),
      field: 'Action',
      sortable: false,
      disableColumnMenu: true,
      width: 200,
      renderCell: ({ row }: any) => {
        const handleEditClick = () => {
          navigate(APP_ROUTES.VEHICLEEDIT, {
            state: { data: { data: row, type: 'edit' } },
          });
        };

        const handleViewClick = () => {
          navigate(APP_ROUTES.VEHICLEVIEW, {
            state: { data: { data: row, type: 'view' } },
          });
        };

        return (
          <>
            {' '}
            <CustomButton
              text={t('VIEW')}
              className='mx-3'
              color={'info'}
              onClick={handleViewClick}
            />
            <CustomButton
              text={t('EDIT')}
              className='mx-3 '
              onClick={handleEditClick}
              color={'secondary'}
            />
          </>
        );
      },
    },
  ];
  const data: any[] = [
    {
      id: '1',
      vechicel_no: 'TN45AB5025',
      vechicel_owner: 'KRN',
      loading_units: '30',
      vechicel_type: '1',
      vechicel_status: 'Moving',
      status: 1,
    },
    {
      id: '2',
      vechicel_no: 'TN28R7758',
      vechicel_owner: 'KRN',
      loading_units: '30',
      vechicel_type: '2',
      vechicel_status: 'HUB',
      status: 2,
    },
    {
      id: '3',
      vechicel_no: 'TN28Z5055',
      vechicel_owner: 'KRN',
      loading_units: '30',
      vechicel_type: '2',
      vechicel_status: 'Store',
      status: 1,
    },
  ];
  return (
    <div>
      <Header
        header={t('VECHICLE')}
        buttonName={t('ADD_VECHICEL')}
        route={APP_ROUTES.VEHICLEADD}
        buttonIcon={AddIcon}
        view={true}
      />
      {loading ? (
        <Grid xs={12} my={5} display={'flex'} justifyContent={'center'}>
          <CircularIndeterminate />
        </Grid>
      ) : (
        <Grid xs={12} mb={5}>
          <Table
            // totalRecords={
            //   petitionTypeCount
            //     ? petitionTypeCount
            //     : petetionList?.totalRecords || 0
            // }
            rows={data}
            columns={columns}
            // handlePagination={handlePagination}
            // paginationModel={{
            //   page: Number(petetionList?.currentPage) - 1 || 0,
            //   pageSize: Number(petetionList?.pagesize) || 5,
            // }}
            rowHeight={60}
          />
        </Grid>
      )}
    </div>
  );
}

export default Vehicel;
