import { Grid, Paper, Typography } from '@mui/material';
import React from 'react';
import './dashboard.scss';
import DashboardCard from './DashboardCard';
import load from '../../../common/icons/container-truck_4694200.png';
import tr from '../../../common/icons/delivery-truck_646224.png';
import completed from '../../../common/icons/delivery-truck.png';
import pending from '../../../common/icons/truck_1144283.png';
import theme from '../../../common/theme';
import Table from '../../../common/Table';
import { GridColDef } from '@mui/x-data-grid';
import { useTranslation } from 'react-i18next';
import { APP_ROUTES } from '../../../common/constants/Routes';
import { BarChart } from '@mui/x-charts';
import { dataset } from '../../../common/constants/dropDownList';

function DashboardScreen() {
  const { t } = useTranslation();
  const columns: GridColDef[] = [
    {
      headerName: t('VECHICLE'),
      field: 'vechicle',
      sortable: false,
      disableColumnMenu: true,
      flex: 1,
    },
    {
      headerName: t('LOCK'),
      field: 'lock',
      sortable: false,
      disableColumnMenu: true,
      flex: 1,
    },
    {
      headerName: t('DATE'),
      field: 'date',
      sortable: false,
      disableColumnMenu: true,
      flex: 1,
    },
    {
      headerName: t('NO_OF_STORES'),
      field: 'no_of_stores',
      sortable: false,
      disableColumnMenu: true,
      flex: 1,
    },
  ];
  const data: any[] = [
    {
      id: '1',
      vechicle: 'TN28B0523 - KKN',
      lock: 'LOCK005',
      date: '27-08-2024',
      no_of_stores: 3,
      status: 'Not yet started',
      vechicle_id: '1',
      lock_id: '1',
      location: [
        {
          previous_location: null,
          route: 1,
          km: 0,
          location_name: 'HUB',
        },
        {
          previous_location: 1,
          route: 2,
          km: 0,
          location_name: 'Salem',
        },
        {
          previous_location: 2,
          route: 3,
          km: 0,
          location_name: 'Gate',
        },
        {
          previous_location: 3,
          route: 4,
          km: 0,
          location_name: 'Mallur',
        },
        {
          previous_location: 4,
          route: 1,
          km: 0,
          location_name: 'HUB',
        },
      ],
    },
    {
      id: '2',
      vechicle: 'TN28V0523 - KKN',
      lock: 'LOCK006',
      date: '27-08-2024',
      no_of_stores: 3,
      status: 'Moving',
      vechicle_id: '1',
      lock_id: '1',
      location: [
        {
          previous_location: null,
          route: 1,
          km: 0,
          location_name: 'HUB',
        },
        {
          previous_location: 1,
          route: 2,
          km: 0,
          location_name: 'Salem',
        },
        {
          previous_location: 2,
          route: 3,
          km: 0,
          location_name: 'Gate',
        },
        {
          previous_location: 3,
          route: 4,
          km: 0,
          location_name: 'Mallur',
        },
        {
          previous_location: 4,
          route: 1,
          km: 0,
          location_name: 'HUB',
        },
      ],
    },
    {
      id: '3',
      vechicle: 'TN28E0523 - KKN',
      lock: 'LOCK015',
      date: '27-08-2024',
      no_of_stores: 3,
      status: 'completed',
      vechicle_id: '1',
      lock_id: '1',
      location: [
        {
          previous_location: null,
          route: 1,
          km: 0,
          location_name: 'HUB',
        },
        {
          previous_location: 1,
          route: 2,
          km: 0,
          location_name: 'Salem',
        },
        {
          previous_location: 2,
          route: 3,
          km: 0,
          location_name: 'Gate',
        },
        {
          previous_location: 3,
          route: 4,
          km: 0,
          location_name: 'Mallur',
        },
        {
          previous_location: 4,
          route: 1,
          km: 0,
          location_name: 'HUB',
        },
      ],
    },
  ];
  const chartSetting = {
    xAxis: [
      {
        label: 'Total Processing Trip',
      },
    ],
    width: 500,
    height: 400,
  };

  return (
    <div>
      <Grid
        item
        xs={12}
        container
        display='flex'
        justifyContent={'space-between'}
        columnSpacing={5}
      >
        <Grid item flexGrow={1}>
          <DashboardCard
            total={'25'}
            content={t('TODAY_ASSIGNED')}
            // classname={'blue-back'}
            icon={load}
            navaigate={APP_ROUTES.ASSIGNWORK}
          />
        </Grid>
        <Grid item flexGrow={1}>
          <DashboardCard
            total={'5'}
            content={t('NOT_YET_STATED')}
            // classname={'red-back'}
            icon={pending}
            navaigate={APP_ROUTES.ASSIGNWORK}
          />
        </Grid>
        <Grid item flexGrow={1}>
          <DashboardCard
            total={'10'}
            content={t('TODAY_MOVING')}
            // classname={'orange-back'}
            icon={tr}
            navaigate={APP_ROUTES.ASSIGNWORK}
          />
        </Grid>
        <Grid item flexGrow={1}>
          <DashboardCard
            total={'10'}
            content={t('TODAY_COMPLETED')}
            // classname={'green-back'}
            icon={completed}
            navaigate={APP_ROUTES.ASSIGNWORK}
          />
        </Grid>
      </Grid>
      {/* <Grid item xs={12} my={5}>
        <BarChart
          dataset={dataset}
          yAxis={[{ scaleType: 'band', dataKey: 'month' }]}
          series={[{ dataKey: 'seoul', label: 'Trip' }]}
          layout='horizontal'
          {...chartSetting}
        />
      </Grid> */}
      <Grid
        item
        xs={12}
        container
        display='flex'
        justifyContent={'space-between'}
        className='data-grid'
      >
        <Grid item xs={12} md={5.5} className='tables-grid'>
          <Paper elevation={3}>
            <Typography
              textAlign={'left'}
              fontSize={25}
              color={theme.palette.common.black}
              fontWeight={600}
              py={2}
              ml={2}
            >
              {t('RECENT_ASSIGN')}
            </Typography>

            <Table columns={columns} rows={data} type='dashboard' />
          </Paper>
        </Grid>

        <Grid item xs={12} md={5.5} className='tables-grid' mb={5}>
          <Paper elevation={3}>
            <Typography
              textAlign={'left'}
              fontSize={25}
              color={theme.palette.common.black}
              fontWeight={600}
              py={2}
              ml={2}
            >
              {t('COMPLETED_ROUTE')}
            </Typography>
            <Table columns={columns} rows={data} type='dashboard' />
          </Paper>
        </Grid>
      </Grid>
    </div>
  );
}

export default DashboardScreen;
