/* eslint-disable jsx-a11y/anchor-is-valid */
import {
  Grid,
  IconButton,
  List,
  ListItemText,
  Typography,
  useTheme,
} from '@mui/material';
import React, { useState } from 'react';
import '../Layout.scss';
import { Link, useNavigate } from 'react-router-dom';
import CloseIcon from '@mui/icons-material/Close';
import { useTranslation } from 'react-i18next';
import { AUTH_ROUTES } from '../../common/constants/Routes';
import MENU from '../../common/constants/Menu';
import LanguageSelector from '../../features/Auth/Login/LanguageSelector';

const LeftMenuList = ({ setOpen }: { setOpen: Function }) => {
  const theme = useTheme();

  const { t } = useTranslation();
  const navigate = useNavigate();
  const [showLanguageModal, setShowLanguageModal] = useState(false);

  const handleMenuItemClick = () => {
    setOpen(false);
  };
  const handleLogOff = () => {
    setOpen(false);
    localStorage.removeItem('user');
    navigate(AUTH_ROUTES.LOGIN);
    window.location.reload();
  };

  return (
    <div className='notification-container'>
      <Grid
        container
        justifyContent='space-between'
        className='notification-drawer-header'
        alignItems='center'
      >
        <Grid item mt={5}>
          <Grid item mt={5}>
            <IconButton onClick={() => setOpen(false)}>
              <CloseIcon />
            </IconButton>
          </Grid>
          <Grid
            container
            justifyContent='space-between'
            alignItems='center'
            spacing={2}
          >
            <Grid item>
              <List className='left-menu-container'>
                {MENU.map((item: any, index: any) => {
                  const isActive =
                    window.location.pathname === item.path
                      ? window.location.pathname === item.path
                      : `/${window.location.pathname.split('/')[1]}` ===
                        item.path;
                  return (
                    <React.Fragment key={index}>
                      {item.path ? (
                        <Link to={item.path} onClick={handleMenuItemClick}>
                          <ListItemText
                            className={`menu-item-container ${
                              index > 0 ? ' mt-10' : ''
                            }`}
                            primary={
                              <Typography
                                color={
                                  isActive
                                    ? theme.palette.primary.main
                                    : theme.palette.common.black
                                }
                                fontWeight={600}
                                fontSize={18}
                              >
                                {t(item.title)}
                              </Typography>
                            }
                          />
                        </Link>
                      ) : (
                        <Link to={''}>
                          <ListItemText
                            primary={
                              <Typography
                                color={theme.palette.common.black}
                                fontWeight={600}
                                fontSize={18}
                                onClick={() => setShowLanguageModal(true)}
                              >
                                {t('SELECT_LANGUAGE')}
                              </Typography>
                            }
                          />
                          <ListItemText
                            className={`menu-item-container${
                              index > 0 ? ' mt-10' : ''
                            }`}
                            primary={
                              <Typography
                                color={theme.palette.common.black}
                                fontWeight={600}
                                fontSize={18}
                                onClick={handleLogOff}
                              >
                                {t(item.title)}
                              </Typography>
                            }
                          />
                        </Link>
                      )}
                    </React.Fragment>
                  );
                })}{' '}
              </List>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <LanguageSelector
        open={showLanguageModal}
        setOpen={setShowLanguageModal}
        contentType='DASHBOARD'
      />
    </div>
  );
};

export default LeftMenuList;
