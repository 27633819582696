import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import Header from '../../../common/Header';
import { APP_ROUTES } from '../../../common/constants/Routes';
import SubdirectoryArrowRightIcon from '@mui/icons-material/SubdirectoryArrowRight';
import LockForm from './LockForm';

function LockEdit() {
  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    watch,
    setValue,
  } = useForm({
    defaultValues: {
      lock_name: location.state.data?.data?.lock_name || '',
      status: location.state.data?.data?.status || '',
    },
  });
  const onSubmit = (data: any) => {
    console.log('Form Data:', data);
    navigate(APP_ROUTES.VEHICLE);
    // Handle form submission logic here
  };
  return (
    <div>
      <Header
        header={t('LOCK_EDIT')}
        route={APP_ROUTES.LOCK}
        buttonName={t('GO_BACK')}
        buttonIcon={SubdirectoryArrowRightIcon}
        view={true}
      />

      <LockForm
        register={register}
        errors={errors}
        handleSubmit={handleSubmit}
        watch={watch}
        view={false}
        control={control}
        setValue={setValue}
        onSubmit={onSubmit}
      />
    </div>
  );
}

export default LockEdit;
