import { combineSlices } from '@reduxjs/toolkit';

import authSlice from './auth/_auth.slice';
import commonSlice from '../common/Common.slice';

const rootReducer = combineSlices(
  authSlice,
  commonSlice,

  commonSlice
);

export default rootReducer;
