/* eslint-disable no-console */
/* eslint-disable consistent-return */
/* eslint-disable camelcase */

import store from '../../src/store/store';

import { setAlertInfo } from './Common.slice';

export const HandleAxiosRequest = async (config: any) => {
  console.log('SDFA');
  try {
    config.headers['Content-Type'] = 'application/json';

    config.data = config.data;

    return config;
  } catch (error) {
    console.log(error);
  }
};
export const HandleAxiosResponse = async (response: any) => {
  console.log('SDFA');
  try {
    const requestedData: any = response.config.data;

    response.data = response.data;
    console.log('SDFA');
    const alertInfo = {
      open: true,
      type: 'SUCCESS',
      description: response?.data?.message,
    };
    store.dispatch(setAlertInfo(alertInfo as any));

    // sessionStorage.clear();

    return response;
  } catch (error) {
    console.log('error', error);
  }
};
export const HandleAxiosResponseWithError = async (error: any) => {
  console.log('SDFA');
  try {
    console.log('error', error);
    if (!error?.response?.data?.message || !error?.response?.data?.message) {
      const alertInfo = {
        open: true,
        type: 'ERROR',
        description: 'Failed to connect with network,  Please try Again later.',
      };
      store.dispatch(setAlertInfo(alertInfo as any));
    } else {
      error.response.data = error?.response?.data;

      const alertInfo = {
        open: true,
        type: 'ERROR',
        description: error?.response?.data?.message
          ? error?.response?.data?.message
          : 'Problem with network,  Please try Again later.',
        errorCode: error?.response?.data?.errorCode,
        errorFields: error?.response?.data?.errorFields,
      };
      store.dispatch(setAlertInfo(alertInfo as any));
    }

    return Promise.reject(error);
  } catch (e) {
    console.log('error', e);
    if (error?.response?.status === 401) {
      console.log('error Occurred');
    } else {
      if (process.env.REACT_APP_MODE !== 'PROD') {
        console.log(e);
      }
    }

    return Promise.reject(error);
  }
};
