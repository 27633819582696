import { Grid, Typography } from '@mui/material';

import './OtpScreen.css';
import CustomButton from '../../../common/ui/CustomButton';
import person from '../../../common/icons/petrolbunk.jpg';
import OTPInput from 'react-otp-input';
import { useEffect, useRef, useState } from 'react';
import { AUTH_ROUTES } from '../../../common/constants/Routes';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  fetchOtpRequest,
  fetchOtpVerify,
  selectOtpResponseInfo,
} from '../../../store/auth/_auth.slice';

const OtpScreen = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const locations = useLocation();
  const [otp, setOtp] = useState('');
  const [email] = useState(locations?.state?.email || '');
  const emailRef = useRef(locations?.state?.email || '');

  const [minutes, setMinutes] = useState(0);
  const [seconds, setSeconds] = useState(0);

  const data = useSelector(selectOtpResponseInfo);

  const handleOtpChange = (otpValue: string) => {
    setOtp(otpValue);
  };
  useEffect(() => {
    const interval = setInterval(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1);
      }
      if (seconds === 0) {
        setOtp('');
        if (minutes === 0) {
          setOtp('');
          clearInterval(interval);
        } else {
          setSeconds(45);
          setMinutes(minutes - 1);
        }
      }
    }, 1000);
    return () => {
      clearInterval(interval);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [seconds]);
  useEffect(() => {
    setMinutes(0);
    setSeconds(Number(data?.time ? data?.time : 0));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);
  const handleSubmit = () => {
    const emailToUse = email || emailRef.current;
    if (otp.length === 6 && Number(otp)) {
      const handleSuccess = () => {
        navigate(AUTH_ROUTES.FORGET_PASSWORD, {
          state: { email: emailToUse },
        });
      };
      const payload: any = {
        email: emailToUse,
        verification_code: otp,
        navigate,
        handleSuccess,
      };
      dispatch(fetchOtpVerify(payload));
    }
  };
  const handePassword = () => {
    const emailToUse = email || emailRef.current;
    if (emailToUse) {
      const payload: any = {
        email: emailToUse,
        subject_type: '1',
      };
      dispatch(fetchOtpRequest(payload));
    } else {
      console.error('Email is not available.');
    }
  };
  return (
    <Grid container justifyContent='center' className='login'>
      <Grid item xs={6} className='login-image'>
        <img src={person} className='person-image' alt='' />
      </Grid>
      <Grid
        item
        xs={6}
        justifyContent='center'
        display={'flex'}
        alignItems={'center'}
        className='login-form-main'
      >
        <Grid
          container
          justifyContent={'center'}
          alignItems={'center'}
          display={'flex'}
          mt={5}
          mx={5}
          // className='forget-password'
        >
          <Grid xs={12} justifyContent={'center'} display={'flex'} my={3}>
            <Typography>Enter the otp</Typography>
          </Grid>
          <Grid xs={12} justifyContent={'center'} display={'flex'} mt={2}>
            <Typography>
              We have sent you one time password to your email at{' '}
              <Typography>{email || emailRef.current}</Typography>
            </Typography>
          </Grid>

          <OTPInput
            numInputs={6}
            placeholder='XXXXXX'
            value={otp}
            onChange={handleOtpChange}
            inputStyle='otp-input-style'
            containerStyle='otp-input-container'
            renderSeparator={<span>-</span>}
            renderInput={(props) => (
              <input
                {...props}
                required
                type='text'
                inputMode='numeric'
                pattern='\d{1}'
                maxLength={1}
              />
            )}
          />
          <Grid xs={12} justifyContent={'center'} display={'flex'} my={3}>
            <Link
              to={''}
              onClick={() => {
                handePassword();
              }}
            >
              Did'nt recived the opt? Resent {seconds}
            </Link>
          </Grid>
          <Grid xs={12} justifyContent={'center'} display={'flex'}>
            <CustomButton
              type='button'
              text='Back'
              className='back-button'
              color='primary'
              onClick={() => {
                navigate(AUTH_ROUTES.LOGIN);
              }}
            />
            <CustomButton
              type='submit'
              text='Verified'
              className='otp-button'
              onClick={handleSubmit}
              color={'secondary'}
              disabled={seconds === 0}
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default OtpScreen;
