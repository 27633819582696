import React from 'react';

import { Grid, Typography } from '@mui/material';
import CommonModal from '../../../common/ui/Modal/CommonModal';
import CustomButton from '../../../common/ui/CustomButton';
import { useTranslation } from 'react-i18next';
import FormInput from '../../../common/ui/Forms/FormInput/FormInput';
import { useForm } from 'react-hook-form';

const UnlockModel = ({
  open,
  setOpen,
  cancel,
}: {
  open: any;
  setOpen: any;
  cancel?: any;
}) => {
  const { t } = useTranslation();

  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    reset,
  } = useForm({
    defaultValues: {
      unlock_reason: '',
    },
  });
  const handledelete = (data: any) => {
    console.log(data);
    // success();
    setOpen(false);
    reset();
  };
  return (
    <CommonModal
      onClose={() => setOpen(false)}
      open={open}
      modalClassName='px-20 py-20'
      boxProps={{ width: 300, height: 170 }}
    >
      <Grid>
        <Typography textAlign={'center'} mb={1}>
          {cancel
            ? t('WHAT_IS_THE_REASON_FOR_CANCEL')
            : t('WHAT_IS_THE_REASON_FOR_UNLOCK')}
        </Typography>
      </Grid>
      <form onSubmit={handleSubmit(handledelete)}>
        <Grid item xs={12} sm={6} md={6} lg={6} xl={6} mb={2}>
          <FormInput
            name={`unlock_reason`}
            type='text'
            placeholder={t('REASON')}
            control={control}
            className='input-from'
            inputProps={{
              ...register(`unlock_reason`, {
                required: {
                  value: true,
                  message: t('ENTER_THE_REASON'),
                },
              }),
            }}
            errors={errors}
          />
        </Grid>

        <Grid display={'flex'} justifyContent={'space-around'}>
          <CustomButton
            text={t('SUBMIT')}
            color='info'
            className='p-5'
            type='submit'
          />

          <CustomButton
            text={t('CANCEL')}
            className='px-5 py-3'
            color={'secondary'}
            onClick={() => setOpen(false)}
          />
        </Grid>
      </form>
    </CommonModal>
  );
};

export default UnlockModel;
